import React from 'react'
import Backend from './Backend'
import Frontend from './Frontend'
import './skills.css'

const Skills = () => {
  return (
    <section className='skills section' id='skills'>
        <div className='skills__titles'>
           <h2 className='section__title'>Skills</h2>
           <span className='section__subtitle'>My technical level</span>

        </div>

        <div className='skills__container container grid'>
            <Frontend/>
            <Backend/>
        </div>
    </section>

  )
}

export default Skills